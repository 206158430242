<template>
  <div>
    <c-tab
      type="pagetop"
      :inlineLabel="true"
      :dense="true"
      :tabItems="tabItems"
      v-model="tab"
      align="left"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :sopEmergencyDocumentClassCd="tab.sopEmergencyDocumentClassCd"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'emergency-relation-info',
  data() {
    return {
      tab: '1',
      tabItems: [
        // 비상시 외부연락 및 협조체계
        { name: '1', icon: 'phone_in_talk', label: '비상연락체계도 및 외부연락', component: () => import(`${'./emergencyContactSystem.vue'}`) },
        // // 유관기관 연락처
        // { name: '7', icon: 'folder', label: '유관기관 연락처', component: () => import(`${'./relatedOrganizations.vue'}`) },
        // // 화학안전공동체
        // { name: '6', icon: 'folder', label: '화학안전공동체', component: () => import(`${'./chemSafetyCommun.vue'}`) },
        // 비상사태 대응팀 조직도
        { name: '2', icon: 'account_tree', label: '비상사태 대응팀 조직도', component: () => import(`${'./selfFireBrigade.vue'}`) },
        // 비상사태 대응팀 주요임무
        { name: '3', icon: 'view_list', label: '비상사태 대응팀 주요임무', component: () => import(`${'./classMission.vue'}`) },
        // 위기관리팀
        { name: '4', icon: 'account_tree', label: '위기관리팀', component: () => import(`${'./crisisManagementTeam.vue'}`) },
        // 집결지/비상대피로
        { name: '5', icon: 'folder', label: '집결지/비상대피로', component: () => import(`${'./gatherinEvacuationRoute.vue'}`),
          sopEmergencyDocumentClassCd: 'SEDCD00002' },
        // 응급상황 대응
        { name: '6', icon: 'phone_in_talk', label: '응급상황 대응', component: () => import(`${'@/pages/sop/eme/EmeContactManage.vue'}`) },
      ],
      editable: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
